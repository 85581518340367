import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Link } from 'react-router-dom';
import { Music, Mail, Phone, Heart } from 'lucide-react';
import { useThemeStore } from '../store/themeStore';
export default function Footer() {
    const { isDark } = useThemeStore();
    return (_jsx("footer", { className: `mt-12 sm:mt-16 md:mt-20 py-12 border-t transition-colors duration-300 ${isDark
            ? 'bg-transparent border-gray-800'
            : 'bg-white/50 border-gray-200'}`, children: _jsxs("div", { className: "max-w-6xl mx-auto px-4", children: [_jsxs("div", { className: "grid md:grid-cols-4 gap-8", children: [_jsxs("div", { children: [_jsxs("div", { className: "flex items-center gap-2 mb-4", children: [_jsx(Music, { className: "w-6 h-6 text-purple-500" }), _jsx("span", { className: "text-xl font-bold text-purple-500", children: "MelodyMade" })] }), _jsx("p", { className: `${isDark ? 'text-gray-300' : 'text-gray-600'}`, children: "Creating personalized musical memories for every special moment in your life." })] }), _jsxs("div", { children: [_jsx("h3", { className: "text-lg font-semibold text-purple-500 mb-4", children: "Quick Links" }), _jsx("ul", { className: "space-y-2", children: ['Home', 'Contact', 'Order Now', 'Legal'].map((item) => (_jsx("li", { children: _jsx(Link, { to: item === 'Home' ? '/' : `/${item.toLowerCase().replace(' ', '-')}`, className: `transition-colors ${isDark
                                                ? 'text-gray-300 hover:text-purple-400'
                                                : 'text-gray-600 hover:text-purple-600'}`, children: item }) }, item))) })] }), _jsxs("div", { children: [_jsx("h3", { className: "text-lg font-semibold text-purple-500 mb-4", children: "Contact Us" }), _jsxs("ul", { className: "space-y-2", children: [_jsx("li", { children: _jsxs("a", { href: "mailto:themelodymade@gmail.com", className: `flex items-center gap-2 transition-colors ${isDark
                                                    ? 'text-gray-300 hover:text-purple-400'
                                                    : 'text-gray-600 hover:text-purple-600'}`, children: [_jsx(Mail, { className: "w-4 h-4" }), "themelodymade@gmail.com"] }) }), _jsx("li", { children: _jsxs("a", { href: "tel:+18568746640", className: `flex items-center gap-2 transition-colors ${isDark
                                                    ? 'text-gray-300 hover:text-purple-400'
                                                    : 'text-gray-600 hover:text-purple-600'}`, children: [_jsx(Phone, { className: "w-4 h-4" }), "(856) 874-6640"] }) })] })] }), _jsxs("div", { children: [_jsx("h3", { className: "text-lg font-semibold text-purple-500 mb-4", children: "Our Promise" }), _jsx("p", { className: `${isDark ? 'text-gray-300' : 'text-gray-600'}`, children: "24-hour delivery guaranteed for all custom songs. Your satisfaction is our priority." })] })] }), _jsxs("div", { className: `mt-12 pt-8 border-t text-center flex items-center justify-center gap-2 transition-colors ${isDark ? 'border-gray-800' : 'border-gray-200'}`, children: [_jsx("span", { className: isDark ? 'text-gray-300' : 'text-gray-600', children: "Made with" }), _jsx(Heart, { className: "w-4 h-4 text-red-400" }), _jsxs("span", { className: isDark ? 'text-gray-300' : 'text-gray-600', children: ["by MelodyMade \u00A9 ", new Date().getFullYear()] })] })] }) }));
}
