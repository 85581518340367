import { FirebaseError } from 'firebase/app';
export const AUTH_ERROR_MESSAGES = {
    'auth/invalid-email': 'Please enter a valid email address',
    'auth/user-disabled': 'This account has been disabled',
    'auth/user-not-found': 'No account found with this email',
    'auth/wrong-password': 'Incorrect password',
    'auth/email-already-in-use': 'An account already exists with this email',
    'auth/weak-password': 'Password should be at least 6 characters',
    'auth/network-request-failed': 'Network error. Please check your connection',
    'auth/too-many-requests': 'Too many attempts. Please try again later',
    'auth/internal-error': 'Please check your email and password',
    'auth/popup-closed-by-user': 'Sign in was cancelled',
    'auth/operation-not-allowed': 'This sign in method is not enabled',
    'auth/popup-blocked': 'Sign in popup was blocked by your browser',
    'default': 'Authentication failed. Please try again'
};
export function getAuthErrorMessage(error) {
    if (error instanceof FirebaseError) {
        return AUTH_ERROR_MESSAGES[error.code] || AUTH_ERROR_MESSAGES.default;
    }
    if (error instanceof Error) {
        return error.message;
    }
    return AUTH_ERROR_MESSAGES.default;
}
