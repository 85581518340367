import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { useState, useEffect } from 'react';
import { Star, Music2, Crown, Rocket, Sparkle } from 'lucide-react';
export default function KidsSection() {
    const [mousePosition, setMousePosition] = useState({ x: 0, y: 0 });
    const [hoveredCard, setHoveredCard] = useState(null);
    useEffect(() => {
        const handleMouseMove = (e) => {
            const { clientX, clientY } = e;
            const x = (clientX / window.innerWidth - 0.5) * 20;
            const y = (clientY / window.innerHeight - 0.5) * 20;
            setMousePosition({ x, y });
        };
        window.addEventListener('mousemove', handleMouseMove);
        return () => window.removeEventListener('mousemove', handleMouseMove);
    }, []);
    const kidsSongs = [
        {
            title: "Custom Kids Songs",
            genre: "Children's",
            character: "Storyteller",
            description: "Turn your child's imagination into magical musical adventures.",
            icon: Crown,
            gradient: "from-pink-500 via-purple-500 to-indigo-500"
        },
        {
            title: "Space Explorer",
            genre: "Adventure",
            character: "Astronaut",
            description: "Blast off into a cosmic journey with your child as the brave space explorer.",
            icon: Rocket,
            gradient: "from-blue-500 via-purple-500 to-pink-500"
        },
        {
            title: "Fairy Tales",
            genre: "Magical",
            character: "Fairy",
            description: "Sprinkle some fairy dust and watch your child's imagination soar with magical fairy adventures.",
            icon: Sparkle,
            gradient: "from-purple-500 via-pink-500 to-yellow-500"
        }
    ];
    return (_jsx("section", { className: "py-12 sm:py-16 md:py-20 relative overflow-hidden", children: _jsxs("div", { className: "max-w-6xl mx-auto px-4", children: [_jsx("h2", { className: "text-3xl md:text-4xl font-bold text-center text-transparent bg-clip-text bg-gradient-to-r from-purple-400 to-pink-400 mb-12 title-border", children: "Magical Kids Songs" }), _jsx("div", { className: "grid md:grid-cols-3 gap-8", children: kidsSongs.map((song, index) => {
                        const Icon = song.icon;
                        const isHovered = hoveredCard === index;
                        return (_jsx("div", { className: "perspective-1000", onMouseEnter: () => setHoveredCard(index), onMouseLeave: () => setHoveredCard(null), children: _jsxs("div", { className: "relative preserve-3d transform-gpu transition-all duration-500 group", style: {
                                    transform: isHovered
                                        ? `
                          translateZ(50px)
                          rotateX(${mousePosition.y * 0.1}deg)
                          rotateY(${mousePosition.x * 0.1}deg)
                        `
                                        : 'translateZ(0) rotateX(0) rotateY(0)',
                                }, children: [_jsx("div", { className: "absolute -inset-1 bg-gradient-to-r from-purple-500/30 via-pink-500/30 to-indigo-500/30 rounded-2xl opacity-20 blur-lg transition-all duration-500 group-hover:opacity-40" }), _jsxs("div", { className: "relative bg-gray-900/30 backdrop-blur-sm rounded-2xl p-8 shadow-xl border border-white/5", children: [_jsx("div", { className: "absolute -top-4 -right-4 transform-gpu transition-all duration-500", style: {
                                                    transform: isHovered ? 'translateZ(30px) rotate(10deg)' : 'translateZ(0) rotate(0)',
                                                }, children: _jsx("div", { className: `bg-gradient-to-r ${song.gradient} p-3 rounded-full shadow-lg`, children: _jsx(Icon, { className: "w-6 h-6 text-white animate-floating" }) }) }), _jsx("h3", { className: "text-2xl font-bold text-white mb-3 transform-gpu transition-all duration-500", style: {
                                                    transform: isHovered ? 'translateZ(20px)' : 'translateZ(0)',
                                                }, children: song.title }), _jsx("p", { className: "text-gray-300 mb-4 transform-gpu transition-all duration-500", style: {
                                                    transform: isHovered ? 'translateZ(10px)' : 'translateZ(0)',
                                                }, children: song.description }), isHovered && (_jsxs(_Fragment, { children: [_jsx(Star, { className: "absolute top-2 left-2 w-3 h-3 text-yellow-300/50 animate-floating" }), _jsx(Music2, { className: "absolute bottom-2 right-2 w-4 h-4 text-purple-300/50 animate-rise" }), _jsx(Music2, { className: "absolute top-1/2 right-4 w-3 h-3 text-pink-300/50 animate-rise", style: { animationDelay: '1s' } }), _jsx(Music2, { className: "absolute bottom-1/2 right-8 w-3 h-3 text-indigo-300/50 animate-rise", style: { animationDelay: '2s' } })] }))] })] }) }, index));
                    }) })] }) }));
}
