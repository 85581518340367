import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
{ /* Previous imports remain the same */ }
import { useNavigate } from 'react-router-dom';
import { Check, Star, Sparkles, Coins, Crown } from 'lucide-react';
export default function PricingSection() {
    const navigate = useNavigate();
    const plans = [
        {
            title: "Single Token",
            price: "$9.99",
            features: [
                "1 Melody Token",
                "1 Token = 1 Custom Song",
                "Two revisions per song",
                "Professional recording",
                "No subscription required",
                "Tokens never expire"
            ]
        },
        {
            title: "Starter",
            price: "$19.99",
            features: [
                "3 Melody Tokens monthly",
                "1 Token = 1 Custom Song",
                "Two revisions per song",
                "Professional recording",
                "Monthly subscription",
                "Tokens never expire"
            ]
        },
        {
            title: "Pro",
            price: "$49.99",
            features: [
                "10 Melody Tokens monthly",
                "1 Token = 1 Custom Song",
                "Priority song delivery",
                "Unlimited revisions",
                "Monthly subscription",
                "HD video accompaniment"
            ],
            popular: true
        },
        {
            title: "VIP Experience",
            price: "Custom",
            features: [
                "Live DJ performances",
                "On-the-spot custom songs",
                "Personalized event planning",
                "Premium sound equipment",
                "Dedicated event coordinator",
                "Custom event packages"
            ],
            isVIP: true
        }
    ];
    const handlePlanSelect = (plan) => {
        if (plan === "VIP Experience") {
            navigate('/vip');
        }
        else {
            navigate('/payment');
        }
    };
    return (_jsxs("section", { className: "py-12 sm:py-16 md:py-20 relative", children: [_jsxs("div", { className: "text-center mb-16", children: [_jsx("h2", { className: "text-4xl font-bold text-transparent bg-clip-text bg-gradient-to-r from-purple-400 to-pink-400 mb-4", children: "Choose Your Token Package" }), _jsx("p", { className: "text-xl text-gray-400 max-w-2xl mx-auto", children: "Purchase Melody Tokens to create your custom songs. Each token can be redeemed for one personalized track." })] }), _jsx("div", { className: "grid md:grid-cols-4 gap-6 max-w-7xl mx-auto px-4", children: plans.map((plan, index) => (_jsxs("div", { className: "relative group animate-fade-in", style: { animationDelay: `${index * 0.2}s` }, children: [_jsx("div", { className: "absolute -inset-0.5 bg-gradient-to-r from-purple-600 to-pink-600 rounded-xl opacity-75 group-hover:opacity-100 blur transition-opacity duration-300" }), _jsxs("div", { className: `relative h-full ${plan.popular ? 'bg-gradient-to-br from-purple-900 to-indigo-900' :
                                plan.isVIP ? 'bg-gradient-to-br from-amber-900 to-purple-900' : 'bg-gray-900'} rounded-xl p-6 shadow-xl`, children: [plan.popular && (_jsx("div", { className: "absolute -top-4 left-1/2 -translate-x-1/2", children: _jsxs("div", { className: "bg-gradient-to-r from-yellow-400 to-orange-400 text-gray-900 text-sm font-bold px-4 py-1 rounded-full flex items-center gap-1", children: [_jsx(Star, { className: "w-4 h-4" }), _jsx(Sparkles, { className: "w-4 h-4 animate-pulse" }), "Best Value"] }) })), plan.isVIP && (_jsx("div", { className: "absolute -top-4 left-1/2 -translate-x-1/2", children: _jsxs("div", { className: "bg-gradient-to-r from-amber-400 to-purple-400 text-gray-900 text-sm font-bold px-4 py-1 rounded-full flex items-center gap-1", children: [_jsx(Crown, { className: "w-4 h-4" }), "VIP Experience"] }) })), _jsxs("div", { className: "mb-8", children: [_jsxs("div", { className: "flex items-center justify-between mb-2", children: [_jsx("h3", { className: "text-2xl font-bold text-purple-400", children: plan.title }), plan.isVIP ? (_jsx(Crown, { className: "w-6 h-6 text-amber-400 animate-pulse" })) : (_jsx(Coins, { className: `w-6 h-6 ${plan.popular ? 'text-yellow-400' : 'text-purple-400'} animate-bounce` }))] }), _jsxs("div", { className: "flex items-baseline", children: [_jsx("span", { className: "text-4xl font-bold text-white", children: plan.price }), !plan.isVIP && plan.title !== "Single Token" && _jsx("span", { className: "text-sm text-gray-400 ml-2", children: "/month" })] })] }), _jsx("ul", { className: "space-y-3 mb-8", children: plan.features.map((feature, featureIndex) => (_jsxs("li", { className: "flex items-center gap-2 group/feature", children: [_jsx("div", { className: "relative", children: _jsx(Check, { className: `w-5 h-5 ${plan.isVIP ? 'text-amber-400' :
                                                        plan.popular ? 'text-yellow-400' : 'text-purple-400'} transition-transform duration-200 group-hover/feature:scale-110` }) }), _jsx("span", { className: "text-gray-300", children: feature })] }, featureIndex))) }), _jsx("button", { onClick: () => handlePlanSelect(plan.title), className: `w-full py-3 px-6 rounded-lg font-semibold transition-all duration-300 ${plan.isVIP
                                        ? 'bg-gradient-to-r from-amber-400 to-purple-400 text-gray-900 hover:from-amber-500 hover:to-purple-500'
                                        : plan.popular
                                            ? 'bg-gradient-to-r from-yellow-400 to-orange-400 text-gray-900 hover:from-yellow-500 hover:to-orange-500'
                                            : 'bg-gradient-to-r from-purple-600 to-pink-600 text-white hover:from-purple-700 hover:to-pink-700'} transform hover:-translate-y-1 hover:shadow-lg hover:shadow-purple-500/25`, children: plan.isVIP ? 'Contact VIP Services' : 'Get Tokens' })] })] }, index))) }), _jsx("div", { className: "mt-16 max-w-2xl mx-auto px-4", children: _jsxs("div", { className: "bg-gray-800/50 backdrop-blur-sm rounded-xl p-6 border border-purple-500/20", children: [_jsxs("h3", { className: "text-xl font-semibold text-purple-400 mb-4 flex items-center gap-2", children: [_jsx(Coins, { className: "w-5 h-5" }), "How Melody Tokens Work"] }), _jsxs("ul", { className: "space-y-3 text-gray-300", children: [_jsxs("li", { className: "flex items-start gap-2", children: [_jsx(Check, { className: "w-5 h-5 text-purple-400 flex-shrink-0 mt-0.5" }), _jsx("span", { children: "Each token can be redeemed for one custom song of your choice" })] }), _jsxs("li", { className: "flex items-start gap-2", children: [_jsx(Check, { className: "w-5 h-5 text-purple-400 flex-shrink-0 mt-0.5" }), _jsx("span", { children: "Choose from various styles including custom rap lyrics" })] }), _jsxs("li", { className: "flex items-start gap-2", children: [_jsx(Check, { className: "w-5 h-5 text-purple-400 flex-shrink-0 mt-0.5" }), _jsx("span", { children: "Pro package tokens include priority delivery and premium features" })] }), _jsxs("li", { className: "flex items-start gap-2", children: [_jsx(Check, { className: "w-5 h-5 text-purple-400 flex-shrink-0 mt-0.5" }), _jsx("span", { children: "Additional tokens can be purchased individually as needed" })] })] })] }) })] }));
}
