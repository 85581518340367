import { GoogleAuthProvider, OAuthProvider, signInWithPopup } from 'firebase/auth';
import { auth } from '../firebase';
import { createUserProfile } from './service';
import { getAuthErrorMessage } from './errors';
import toast from 'react-hot-toast';
const googleProvider = new GoogleAuthProvider();
const appleProvider = new OAuthProvider('apple.com');
export const signInWithGoogle = async () => {
    try {
        const result = await signInWithPopup(auth, googleProvider);
        const isNewUser = result.additionalUserInfo?.isNewUser;
        if (isNewUser) {
            await createUserProfile(result.user.uid, {
                email: result.user.email || '',
                name: result.user.displayName || '',
                tokens: 1,
                purchaseHistory: [{
                        date: new Date().toISOString(),
                        amount: 0,
                        tokens: 1,
                        transactionId: 'welcome-bonus'
                    }]
            });
            toast.success('Welcome! You received 1 free token.');
        }
        else {
            toast.success('Welcome back!');
        }
        return result.user;
    }
    catch (error) {
        console.error('Google sign-in error:', error);
        const message = getAuthErrorMessage(error);
        toast.error(message);
        throw new Error(message);
    }
};
export const signInWithApple = async () => {
    try {
        const result = await signInWithPopup(auth, appleProvider);
        const isNewUser = result.additionalUserInfo?.isNewUser;
        if (isNewUser) {
            await createUserProfile(result.user.uid, {
                email: result.user.email || '',
                name: result.user.displayName || '',
                tokens: 1,
                purchaseHistory: [{
                        date: new Date().toISOString(),
                        amount: 0,
                        tokens: 1,
                        transactionId: 'welcome-bonus'
                    }]
            });
            toast.success('Welcome! You received 1 free token.');
        }
        else {
            toast.success('Welcome back!');
        }
        return result.user;
    }
    catch (error) {
        console.error('Apple sign-in error:', error);
        const message = getAuthErrorMessage(error);
        toast.error(message);
        throw new Error(message);
    }
};
