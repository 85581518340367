import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useAuthStore } from '../store/authStore';
import { Music2, Clock, CreditCard, ArrowRight, Disc3, Gift } from 'lucide-react';
import Footer from '../components/Footer';
import ParallaxBackground from '../components/ParallaxBackground';
import { useNavigate } from 'react-router-dom';
export default function Dashboard() {
    const { user, profile } = useAuthStore();
    const navigate = useNavigate();
    if (!user) {
        navigate('/');
        return null;
    }
    const quickActions = [
        {
            icon: Music2,
            title: 'Create New Song',
            path: '/song-builder',
            description: 'Start creating your custom song'
        },
        {
            icon: Disc3,
            title: 'Your Jukebox',
            path: '/jukebox',
            description: 'Listen to your custom songs'
        },
        {
            icon: Gift,
            title: 'Free Sample',
            path: '/free-sample',
            description: 'Get a free sample song'
        }
    ];
    return (_jsxs("div", { className: "min-h-screen bg-gray-900 text-gray-100 pt-24 relative", children: [_jsx(ParallaxBackground, {}), _jsxs("div", { className: "relative max-w-6xl mx-auto px-4 pb-12", children: [_jsxs("div", { className: "text-center mb-12", children: [_jsx("h1", { className: "text-4xl font-bold text-purple-400 mb-4", children: "Your Dashboard" }), _jsx("p", { className: "text-xl text-gray-400", children: "Manage your tokens and create amazing songs" })] }), _jsxs("div", { className: "grid md:grid-cols-2 gap-8", children: [_jsxs("div", { className: "bg-gray-800/90 backdrop-blur-sm rounded-xl p-6 shadow-xl", children: [_jsxs("div", { className: "flex items-center justify-between mb-6", children: [_jsx("h2", { className: "text-2xl font-semibold text-purple-400", children: "Token Balance" }), _jsxs("div", { className: "flex items-center gap-2", children: [_jsx(Music2, { className: "w-6 h-6 text-purple-400" }), _jsx("span", { className: "text-3xl font-bold text-white", children: profile?.tokens || 0 })] })] }), _jsxs("button", { onClick: () => navigate('/payment'), className: "w-full bg-gradient-to-r from-purple-600 to-indigo-600 text-white py-3 px-6 rounded-lg hover:from-purple-700 hover:to-indigo-700 transition-all duration-200 flex items-center justify-center gap-2", children: [_jsx(CreditCard, { className: "w-5 h-5" }), "Purchase More Tokens"] })] }), _jsxs("div", { className: "bg-gray-800/90 backdrop-blur-sm rounded-xl p-6 shadow-xl", children: [_jsx("h2", { className: "text-2xl font-semibold text-purple-400 mb-6", children: "Quick Actions" }), _jsx("div", { className: "space-y-3", children: quickActions.map((action) => {
                                            const Icon = action.icon;
                                            return (_jsxs("button", { onClick: () => navigate(action.path), className: "w-full bg-gray-700/50 hover:bg-gray-700 text-white py-3 px-6 rounded-lg transition-all duration-200 flex items-center justify-between group", children: [_jsxs("div", { className: "flex items-center gap-3", children: [_jsx("div", { className: "p-2 bg-purple-500/20 rounded-lg group-hover:bg-purple-500/30 transition-colors", children: _jsx(Icon, { className: "w-5 h-5 text-purple-400" }) }), _jsxs("div", { className: "text-left", children: [_jsx("div", { className: "font-medium", children: action.title }), _jsx("div", { className: "text-sm text-gray-400", children: action.description })] })] }), _jsx(ArrowRight, { className: "w-5 h-5 text-gray-400 group-hover:text-white transform group-hover:translate-x-1 transition-all" })] }, action.path));
                                        }) })] })] }), _jsxs("div", { className: "mt-8 bg-gray-800/90 backdrop-blur-sm rounded-xl p-6 shadow-xl", children: [_jsx("h2", { className: "text-2xl font-semibold text-purple-400 mb-6", children: "Purchase History" }), _jsx("div", { className: "space-y-4", children: profile?.purchaseHistory?.length ? (profile.purchaseHistory.map((purchase, index) => (_jsxs("div", { className: "flex items-center justify-between p-4 bg-gray-700/50 rounded-lg", children: [_jsxs("div", { children: [_jsxs("p", { className: "text-white font-medium", children: [purchase.tokens, " ", purchase.tokens === 1 ? 'Token' : 'Tokens', " Purchased"] }), _jsx("p", { className: "text-sm text-gray-400", children: new Date(purchase.date).toLocaleDateString() })] }), _jsxs("div", { className: "text-right", children: [_jsxs("p", { className: "text-white font-medium", children: ["$", purchase.amount.toFixed(2)] }), _jsxs("p", { className: "text-xs text-gray-400", children: ["ID: ", purchase.transactionId] })] })] }, index)))) : (_jsxs("div", { className: "text-center py-8", children: [_jsx(Clock, { className: "w-12 h-12 text-gray-600 mx-auto mb-4" }), _jsx("p", { className: "text-gray-400", children: "No purchase history available" })] })) })] })] }), _jsx(Footer, {})] }));
}
