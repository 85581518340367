import { signInWithEmailAndPassword, createUserWithEmailAndPassword, sendPasswordResetEmail as firebaseResetPassword } from 'firebase/auth';
import { doc, setDoc, getDoc } from 'firebase/firestore';
import { auth, db } from '../firebase';
import { getAuthErrorMessage } from './errors';
import toast from 'react-hot-toast';
export const createUserProfile = async (uid, userData) => {
    try {
        const userRef = doc(db, 'users', uid);
        const userDoc = await getDoc(userRef);
        if (!userDoc.exists()) {
            const profile = {
                uid,
                email: userData.email || '',
                name: userData.name || '',
                phone: userData.phone || '',
                address: userData.address || '',
                city: userData.city || '',
                state: userData.state || '',
                zipCode: userData.zipCode || '',
                tokens: userData.tokens || 1,
                purchaseHistory: userData.purchaseHistory || [{
                        date: new Date().toISOString(),
                        amount: 0,
                        tokens: 1,
                        transactionId: 'welcome-bonus'
                    }]
            };
            await setDoc(userRef, profile);
            return profile;
        }
        return userDoc.data();
    }
    catch (error) {
        console.error('Error creating user profile:', error);
        throw new Error('Failed to create user profile');
    }
};
export const signUp = async (email, password, userData) => {
    try {
        const userCredential = await createUserWithEmailAndPassword(auth, email, password);
        await createUserProfile(userCredential.user.uid, {
            ...userData,
            email,
            tokens: 1,
            purchaseHistory: [{
                    date: new Date().toISOString(),
                    amount: 0,
                    tokens: 1,
                    transactionId: 'welcome-bonus'
                }]
        });
        return userCredential.user;
    }
    catch (error) {
        console.error('Sign up error:', error);
        throw new Error(getAuthErrorMessage(error));
    }
};
export const signIn = async (email, password) => {
    try {
        const userCredential = await signInWithEmailAndPassword(auth, email, password);
        return userCredential.user;
    }
    catch (error) {
        console.error('Sign in error:', error);
        throw new Error(getAuthErrorMessage(error));
    }
};
export const sendPasswordResetEmail = async (email) => {
    try {
        await firebaseResetPassword(auth, email);
        toast.success('Password reset email sent!');
    }
    catch (error) {
        console.error('Password reset error:', error);
        throw new Error(getAuthErrorMessage(error));
    }
};
