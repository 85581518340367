import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { useState } from 'react';
import { X, Coins, AlertCircle } from 'lucide-react';
import { useAuthStore } from '../store/authStore';
import { createCheckoutSession } from '../lib/stripe';
import toast from 'react-hot-toast';
export default function TokenPurchaseModal({ isOpen, onClose }) {
    const [selectedPackage, setSelectedPackage] = useState('price_single');
    const { user } = useAuthStore();
    const [loading, setLoading] = useState(false);
    const handlePurchase = async () => {
        if (!user) {
            toast.error('Please sign in to purchase tokens');
            return;
        }
        try {
            setLoading(true);
            await createCheckoutSession(selectedPackage, user.uid);
        }
        catch (error) {
            console.error('Purchase error:', error);
            toast.error('Failed to process purchase');
        }
        finally {
            setLoading(false);
        }
    };
    if (!isOpen)
        return null;
    return (_jsx("div", { className: "fixed inset-0 z-50 flex items-center justify-center bg-black/50 backdrop-blur-sm", children: _jsxs("div", { className: "relative w-full max-w-md bg-gray-900 rounded-xl p-8 shadow-2xl", children: [_jsx("button", { onClick: onClose, className: "absolute top-4 right-4 text-gray-400 hover:text-white", children: _jsx(X, { className: "w-6 h-6" }) }), _jsx("h2", { className: "text-2xl font-bold text-purple-400 mb-6", children: "Purchase Tokens" }), _jsxs("div", { className: "space-y-4 mb-6", children: [_jsxs("button", { onClick: () => setSelectedPackage('price_single'), className: `w-full flex items-center justify-between p-4 rounded-lg border-2 transition-all duration-200 ${selectedPackage === 'price_single'
                                ? 'border-purple-500 bg-purple-500/20'
                                : 'border-gray-700 hover:border-purple-500/50'}`, children: [_jsxs("div", { className: "flex items-center gap-3", children: [_jsx(Coins, { className: "w-5 h-5 text-purple-400" }), _jsxs("div", { className: "text-left", children: [_jsx("div", { className: "font-medium text-white", children: "Single Token" }), _jsx("div", { className: "text-sm text-gray-400", children: "1 token" })] })] }), _jsx("div", { className: "text-lg font-semibold text-white", children: "$9.99" })] }), _jsxs("button", { onClick: () => setSelectedPackage('price_starter'), className: `w-full flex items-center justify-between p-4 rounded-lg border-2 transition-all duration-200 ${selectedPackage === 'price_starter'
                                ? 'border-purple-500 bg-purple-500/20'
                                : 'border-gray-700 hover:border-purple-500/50'}`, children: [_jsxs("div", { className: "flex items-center gap-3", children: [_jsx(Coins, { className: "w-5 h-5 text-purple-400" }), _jsxs("div", { className: "text-left", children: [_jsx("div", { className: "font-medium text-white", children: "Starter Package" }), _jsx("div", { className: "text-sm text-gray-400", children: "3 tokens monthly" })] })] }), _jsx("div", { className: "text-lg font-semibold text-white", children: "$19.99" })] }), _jsxs("button", { onClick: () => setSelectedPackage('price_pro'), className: `w-full flex items-center justify-between p-4 rounded-lg border-2 transition-all duration-200 ${selectedPackage === 'price_pro'
                                ? 'border-purple-500 bg-purple-500/20'
                                : 'border-gray-700 hover:border-purple-500/50'}`, children: [_jsxs("div", { className: "flex items-center gap-3", children: [_jsx(Coins, { className: "w-5 h-5 text-purple-400" }), _jsxs("div", { className: "text-left", children: [_jsx("div", { className: "font-medium text-white", children: "Pro Package" }), _jsx("div", { className: "text-sm text-gray-400", children: "10 tokens monthly" })] })] }), _jsx("div", { className: "text-lg font-semibold text-white", children: "$49.99" })] })] }), !user && (_jsxs("div", { className: "mb-6 p-4 bg-yellow-500/10 border border-yellow-500/20 rounded-lg flex items-start gap-3", children: [_jsx(AlertCircle, { className: "w-5 h-5 text-yellow-500 flex-shrink-0 mt-0.5" }), _jsx("p", { className: "text-sm text-yellow-200", children: "Please sign in to purchase tokens. Your tokens will be safely stored in your account." })] })), _jsx("button", { onClick: handlePurchase, disabled: !user || loading, className: "w-full bg-gradient-to-r from-purple-600 to-indigo-600 text-white py-3 px-6 rounded-lg hover:from-purple-700 hover:to-indigo-700 transition-all duration-200 disabled:opacity-50 flex items-center justify-center gap-2", children: loading ? (_jsxs(_Fragment, { children: [_jsx("div", { className: "w-5 h-5 border-2 border-white/30 border-t-white rounded-full animate-spin" }), "Processing..."] })) : (_jsxs(_Fragment, { children: [_jsx(Coins, { className: "w-5 h-5" }), user ? 'Complete Purchase' : 'Sign in to Purchase'] })) })] }) }));
}
