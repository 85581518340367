import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useState } from 'react';
import { Music2, Plus, LogIn } from 'lucide-react';
import Footer from '../components/Footer';
import ParallaxBackground from '../components/ParallaxBackground';
import { useAuthStore } from '../store/authStore';
import AuthModal from '../components/AuthModal';
import TokenPurchaseModal from '../components/TokenPurchaseModal';
export default function Payment() {
    const { user } = useAuthStore();
    const [showAuthModal, setShowAuthModal] = useState(false);
    const [showTokenModal, setShowTokenModal] = useState(false);
    if (!user) {
        return (_jsxs("div", { className: "min-h-screen text-gray-100 pt-24 relative", children: [_jsx(ParallaxBackground, {}), _jsxs("div", { className: "relative max-w-3xl mx-auto px-4 pb-12", children: [_jsxs("div", { className: "text-center mb-12", children: [_jsx("h1", { className: "text-4xl font-bold text-purple-400 mb-4", children: "Complete Your Order" }), _jsx("p", { className: "text-gray-400", children: "Please sign in or create an account to purchase tokens" })] }), _jsxs("div", { className: "bg-gray-800/90 backdrop-blur-sm rounded-xl p-8 shadow-xl text-center", children: [_jsx(LogIn, { className: "w-12 h-12 text-purple-400 mx-auto mb-6" }), _jsx("h2", { className: "text-2xl font-semibold text-white mb-4", children: "Account Required" }), _jsx("p", { className: "text-gray-300 mb-6", children: "To purchase tokens and create custom songs, please sign in to your account or create a new one." }), _jsxs("button", { onClick: () => setShowAuthModal(true), className: "bg-gradient-to-r from-purple-600 to-indigo-600 text-white py-3 px-6 rounded-lg hover:from-purple-700 hover:to-indigo-700 transition-all duration-200 flex items-center justify-center gap-2 mx-auto", children: [_jsx(LogIn, { className: "w-5 h-5" }), "Sign In / Create Account"] })] })] }), _jsx(Footer, {}), _jsx(AuthModal, { isOpen: showAuthModal, onClose: () => setShowAuthModal(false) })] }));
    }
    return (_jsxs("div", { className: "min-h-screen text-gray-100 pt-24 relative", children: [_jsx(ParallaxBackground, {}), _jsxs("div", { className: "relative max-w-3xl mx-auto px-4 pb-12", children: [_jsxs("div", { className: "text-center mb-12", children: [_jsx("h1", { className: "text-4xl font-bold text-purple-400 mb-4", children: "Your Token Balance" }), _jsx("p", { className: "text-gray-400", children: "Purchase tokens to create custom songs" })] }), _jsxs("div", { className: "bg-gray-800/90 backdrop-blur-sm rounded-xl p-8 shadow-xl", children: [_jsxs("div", { className: "text-center mb-8", children: [_jsx("div", { className: "inline-flex items-center justify-center w-20 h-20 bg-purple-500/20 rounded-full mb-4", children: _jsx(Music2, { className: "w-10 h-10 text-purple-400" }) }), _jsxs("h2", { className: "text-2xl font-bold text-white", children: [user.tokens || 0, " Tokens Available"] }), _jsx("p", { className: "text-gray-400 mt-2", children: "Each token can be used to create one custom song" })] }), _jsxs("button", { onClick: () => setShowTokenModal(true), className: "w-full bg-gradient-to-r from-purple-600 to-indigo-600 text-white py-4 px-6 rounded-lg hover:from-purple-700 hover:to-indigo-700 transition-all duration-200 flex items-center justify-center gap-2 text-lg font-semibold", children: [_jsx(Plus, { className: "w-5 h-5" }), "Purchase More Tokens"] })] })] }), _jsx(Footer, {}), _jsx(TokenPurchaseModal, { isOpen: showTokenModal, onClose: () => setShowTokenModal(false) })] }));
}
