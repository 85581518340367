import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useEffect, useState } from 'react';
import { Music, Sparkles, Star } from 'lucide-react';
import { useThemeStore } from '../store/themeStore';
export default function LoadingScreen({ onComplete }) {
    const { isDark } = useThemeStore();
    const [mousePosition, setMousePosition] = useState({ x: 0, y: 0 });
    const [rotation, setRotation] = useState({ x: 0, y: 0, z: 0 });
    useEffect(() => {
        const timer = setTimeout(() => {
            onComplete?.();
        }, 1200);
        const handleMouseMove = (e) => {
            const x = (e.clientX / window.innerWidth - 0.5) * 40;
            const y = (e.clientY / window.innerHeight - 0.5) * 40;
            setMousePosition({ x, y });
            setRotation({
                x: -y * 0.1,
                y: x * 0.1,
                z: (x + y) * 0.05
            });
        };
        window.addEventListener('mousemove', handleMouseMove);
        return () => {
            clearTimeout(timer);
            window.removeEventListener('mousemove', handleMouseMove);
        };
    }, [onComplete]);
    return (_jsx("div", { className: `fixed inset-0 z-50 flex items-center justify-center transition-colors duration-300 ${isDark ? 'bg-gray-900/95' : 'bg-white/95'} backdrop-blur-xl perspective-2000`, children: _jsxs("div", { className: "relative transform-gpu preserve-3d", style: {
                transform: `
            perspective(2000px)
            rotateX(${rotation.x}deg)
            rotateY(${rotation.y}deg)
            rotateZ(${rotation.z}deg)
          `
            }, children: [_jsxs("div", { className: "relative w-64 h-64 preserve-3d", children: [[...Array(3)].map((_, ringIndex) => (_jsx("div", { className: "absolute inset-0 preserve-3d", style: {
                                transform: `rotateX(${60 * ringIndex}deg) rotateY(${45 * ringIndex}deg)`,
                                animation: `spin ${6 + ringIndex * 2}s linear infinite`
                            }, children: [...Array(8)].map((_, particleIndex) => (_jsx("div", { className: "absolute", style: {
                                    transform: `
                      rotateY(${(360 / 8) * particleIndex}deg)
                      translateZ(100px)
                      translateY(${Math.sin(particleIndex) * 20}px)
                    `
                                }, children: _jsx(Star, { className: `w-6 h-6 transition-colors duration-300 ${isDark ? 'text-purple-400/50' : 'text-purple-600/50'} animate-pulse`, style: {
                                        animationDelay: `${(ringIndex + particleIndex) * 0.1}s`,
                                        transform: 'translateZ(20px)',
                                    } }) }, `particle-${ringIndex}-${particleIndex}`))) }, `ring-${ringIndex}`))), [...Array(30)].map((_, index) => (_jsx("div", { className: `absolute w-1 h-1 rounded-full transition-colors duration-300 ${isDark ? 'bg-purple-400/30' : 'bg-purple-600/30'}`, style: {
                                left: `${Math.random() * 100}%`,
                                top: `${Math.random() * 100}%`,
                                transform: `
                  translateZ(${Math.random() * 200 - 100}px)
                  scale(${Math.random() * 0.5 + 0.5})
                `,
                                animation: `float ${3 + Math.random() * 4}s ease-in-out infinite`,
                                animationDelay: `${-Math.random() * 3}s`,
                            } }, `particle-${index}`))), _jsx("div", { className: "absolute inset-0 flex items-center justify-center transform-gpu preserve-3d", style: { transform: 'translateZ(50px)' }, children: _jsxs("div", { className: "relative", children: [_jsx("div", { className: `absolute inset-0 blur-2xl transition-colors duration-300 ${isDark ? 'bg-purple-500/30' : 'bg-purple-600/30'} rounded-full scale-150 animate-pulse` }), _jsxs("div", { className: "relative transform-gpu hover:scale-110 transition-transform duration-300", children: [_jsx(Music, { className: `w-20 h-20 transition-colors duration-300 ${isDark ? 'text-white' : 'text-gray-900'}`, style: {
                                                    animation: 'bounce 2s ease-in-out infinite',
                                                    filter: `drop-shadow(0 0 10px ${isDark ? '#a855f7' : '#7c3aed'})`
                                                } }), _jsx(Sparkles, { className: "absolute -top-4 -right-4 w-8 h-8 text-yellow-400 animate-pulse", style: { filter: 'drop-shadow(0 0 5px #fbbf24)' } })] })] }) })] }), _jsxs("div", { className: "text-center mt-16 preserve-3d", style: { transform: 'translateZ(30px)' }, children: [_jsx("p", { className: "text-2xl font-medium mb-4", children: _jsx("span", { className: "bg-gradient-to-r from-purple-400 to-pink-400 text-transparent bg-clip-text animate-gradient-text", children: "Creating Your Musical Magic" }) }), _jsx("div", { className: "flex items-center justify-center gap-3", children: [...Array(3)].map((_, i) => (_jsx("div", { className: `w-3 h-3 rounded-full transition-colors duration-300 ${isDark ? 'bg-purple-400' : 'bg-purple-600'}`, style: {
                                    animation: 'bounce 1.2s ease-in-out infinite',
                                    animationDelay: `${i * 0.2}s`,
                                    filter: `drop-shadow(0 0 5px ${isDark ? '#a855f7' : '#7c3aed'})`
                                } }, i))) })] })] }) }));
}
