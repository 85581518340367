import React, { useEffect, useState } from 'react';
import { BrowserRouter as Router, Routes, Route, Navigate } from 'react-router-dom';
import { useThemeStore } from './store/themeStore';
import FloatingNav from './components/FloatingNav';
import Footer from './components/Footer';
import Contact from './pages/Contact';
import Payment from './pages/Payment';
import FAQ from './pages/FAQ';
import FreeSamples from './pages/FreeSamples';
import VIP from './pages/VIP';
import Create from './pages/Create';
import Dashboard from './pages/Dashboard';
import Admin from './pages/Admin';
import Jukebox from './pages/Jukebox';
import Samples from './pages/Samples';
import Upload from './pages/Upload';
import Legal from './pages/Legal';
import Home from './pages/Home';
import { useAuthStore } from './store/authStore';
import LoadingScreen from './components/LoadingScreen';

function App() {
  const { isDark } = useThemeStore();
  const { user } = useAuthStore();
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    // Set dark mode by default
    document.documentElement.classList.add('dark');

    // Show loading screen for 1.3 seconds
    const timer = setTimeout(() => {
      setLoading(false);
    }, 1300);

    return () => clearTimeout(timer);
  }, []);

  if (loading) {
    return <LoadingScreen onComplete={() => setLoading(false)} />;
  }

  return (
    <Router>
      <div className={`min-h-screen theme-transition ${isDark ? 'dark' : ''}`}>
        <FloatingNav />
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/contact" element={<Contact />} />
          <Route path="/payment" element={<Payment />} />
          <Route path="/faq" element={<FAQ />} />
          <Route path="/free-sample" element={<FreeSamples />} />
          <Route path="/vip" element={<VIP />} />
          <Route path="/create" element={<Create />} />
          <Route 
            path="/dashboard" 
            element={user ? <Dashboard /> : <Navigate to="/" />} 
          />
          <Route 
            path="/admin" 
            element={
              user?.email === 'alexluciano369@gmail.com' 
                ? <Admin /> 
                : <Navigate to="/" />
            } 
          />
          <Route 
            path="/jukebox" 
            element={user ? <Jukebox /> : <Navigate to="/" />} 
          />
          <Route path="/samples" element={<Samples />} />
          <Route 
            path="/upload" 
            element={user ? <Upload /> : <Navigate to="/" />} 
          />
          <Route path="/legal" element={<Legal />} />
          <Route path="*" element={<Navigate to="/" replace />} />
        </Routes>
      </div>
    </Router>
  );
}

export default App;