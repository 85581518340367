import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Quote } from 'lucide-react';
export default function ReviewsSection() {
    const reviews = [
        {
            content: "The song they created for our wedding was absolutely perfect. It captured our story in ways we never imagined possible.",
            author: "Emily & James",
            occasion: "Wedding Song",
            imageUrl: "https://images.unsplash.com/photo-1523824921871-d6f1a15151f1"
        },
        {
            content: "I commissioned a birthday song for my daughter, and she was moved to tears. The attention to detail was incredible.",
            author: "Michael R.",
            occasion: "Birthday Gift",
            imageUrl: "https://images.unsplash.com/photo-1472099645785-5658abf4ff4e"
        },
        {
            content: "The lullaby they created for my newborn is simply magical. It's become an essential part of our bedtime routine.",
            author: "Sarah M.",
            occasion: "Baby's Lullaby",
            imageUrl: "https://images.unsplash.com/photo-1544005313-94ddf0286df2"
        },
        {
            content: "They turned my proposal into a beautiful song. When I played it for her, it made the moment even more special.",
            author: "David K.",
            occasion: "Marriage Proposal",
            imageUrl: "https://images.unsplash.com/photo-1506794778202-cad84cf45f1d"
        }
    ];
    return (_jsxs("section", { className: "py-12 sm:py-16 md:py-20 relative", children: [_jsxs("div", { className: "text-center mb-16", children: [_jsx("h2", { className: "text-4xl font-bold text-transparent bg-clip-text bg-gradient-to-r from-purple-400 to-pink-400 mb-4", children: "What Our Customers Say" }), _jsx("p", { className: "text-xl text-gray-400 max-w-2xl mx-auto", children: "Read about the experiences of those who've already received their custom songs." })] }), _jsx("div", { className: "grid md:grid-cols-2 gap-8 max-w-6xl mx-auto px-4", children: reviews.map((review, index) => (_jsx("div", { className: "group perspective-1000", children: _jsxs("div", { className: "relative preserve-3d transform-gpu transition-all duration-500 hover:translate-z-12", children: [_jsx("div", { className: "absolute -inset-1 bg-gradient-to-r from-purple-500 via-pink-500 to-indigo-500 rounded-2xl opacity-0 group-hover:opacity-30 blur-xl transition-all duration-500" }), _jsx("div", { className: "relative bg-gray-800/90 backdrop-blur-sm rounded-xl p-6 shadow-lg border border-white/10", children: _jsxs("div", { className: "flex items-start gap-4", children: [_jsx(Quote, { className: "w-8 h-8 text-purple-400 flex-shrink-0" }), _jsxs("div", { children: [_jsx("p", { className: "text-gray-300 mb-4", children: review.content }), _jsxs("div", { className: "flex items-center gap-3", children: [_jsx("img", { src: review.imageUrl, alt: review.author, className: "w-10 h-10 rounded-full object-cover" }), _jsxs("div", { children: [_jsx("p", { className: "font-semibold text-purple-400", children: review.author }), _jsx("p", { className: "text-sm text-gray-400", children: review.occasion })] })] })] })] }) })] }) }, index))) })] }));
}
