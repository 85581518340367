import { initializeApp } from 'firebase/app';
import { getAuth, setPersistence, browserLocalPersistence } from 'firebase/auth';
import { getFirestore } from 'firebase/firestore';
const firebaseConfig = {
    apiKey: import.meta.env.VITE_FIREBASE_API_KEY,
    authDomain: import.meta.env.VITE_FIREBASE_AUTH_DOMAIN,
    projectId: import.meta.env.VITE_FIREBASE_PROJECT_ID,
    storageBucket: import.meta.env.VITE_FIREBASE_STORAGE_BUCKET,
    messagingSenderId: import.meta.env.VITE_FIREBASE_MESSAGING_SENDER_ID,
    appId: import.meta.env.VITE_FIREBASE_APP_ID
};
// Initialize Firebase
let app;
try {
    app = initializeApp(firebaseConfig);
}
catch (error) {
    console.error('Firebase initialization error:', error);
    throw new Error('Failed to initialize Firebase. Please check your configuration.');
}
// Initialize Auth with persistence
const auth = getAuth(app);
try {
    await setPersistence(auth, browserLocalPersistence);
}
catch (error) {
    console.error('Auth persistence setup error:', error);
    // Continue without persistence rather than breaking the app
}
// Initialize Firestore
const db = getFirestore(app);
export { auth, db };
