import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Clock, Music2, Heart, Award, Sparkles, Star } from 'lucide-react';
export default function WhyChooseUsSection() {
    const features = [
        {
            icon: Clock,
            title: "24-Hour Delivery",
            description: "Get your custom song within 24 hours, guaranteed. Perfect for last-minute gifts!",
            gradient: "from-purple-500 via-pink-500 to-indigo-500"
        },
        {
            icon: Music2,
            title: "Professional Quality",
            description: "Studio-grade recordings by experienced musicians and producers.",
            gradient: "from-blue-500 via-purple-500 to-pink-500"
        },
        {
            icon: Heart,
            title: "Personalized Touch",
            description: "Every song is uniquely crafted to capture your special moments and memories.",
            gradient: "from-pink-500 via-purple-500 to-indigo-500"
        },
        {
            icon: Award,
            title: "Satisfaction Guaranteed",
            description: "Love your song or get your money back. We're committed to your happiness.",
            gradient: "from-indigo-500 via-purple-500 to-pink-500"
        }
    ];
    return (_jsxs("section", { className: "py-12 sm:py-16 md:py-20 relative overflow-hidden", children: [_jsx("div", { className: "absolute inset-0 opacity-30", children: [...Array(20)].map((_, i) => (_jsx(Star, { className: "absolute text-purple-400 animate-floating", style: {
                        left: `${Math.random() * 100}%`,
                        top: `${Math.random() * 100}%`,
                        animationDelay: `${Math.random() * 5}s`,
                        transform: `scale(${Math.random() * 0.5 + 0.5})`,
                    } }, i))) }), _jsxs("div", { className: "relative max-w-6xl mx-auto px-4", children: [_jsxs("div", { className: "text-center mb-16", children: [_jsx("h2", { className: "text-4xl font-bold text-transparent bg-clip-text bg-gradient-to-r from-purple-400 to-pink-400 mb-4", children: "Why Choose MelodyMade?" }), _jsx("p", { className: "text-xl text-gray-400 max-w-2xl mx-auto", children: "We combine musical expertise with personal touch to create unforgettable memories." })] }), _jsx("div", { className: "grid md:grid-cols-2 gap-8", children: features.map((feature, index) => {
                            const Icon = feature.icon;
                            return (_jsx("div", { className: "group perspective-1000", children: _jsxs("div", { className: "relative preserve-3d transform-gpu transition-all duration-500 hover:translate-z-12", children: [_jsx("div", { className: `absolute -inset-1 bg-gradient-to-r ${feature.gradient} rounded-2xl opacity-0 group-hover:opacity-30 blur-xl transition-all duration-500` }), _jsx("div", { className: "relative bg-gray-800/90 backdrop-blur-sm rounded-2xl p-6 border border-white/10", children: _jsxs("div", { className: "flex items-start gap-4", children: [_jsxs("div", { className: `relative p-3 rounded-xl bg-gradient-to-r ${feature.gradient}`, children: [_jsx(Icon, { className: "w-6 h-6 text-white" }), _jsx(Sparkles, { className: "absolute -top-1 -right-1 w-3 h-3 text-yellow-300 animate-sparkle" })] }), _jsxs("div", { children: [_jsx("h3", { className: "text-xl font-semibold text-white mb-2", children: feature.title }), _jsx("p", { className: "text-gray-300", children: feature.description })] })] }) })] }) }, index));
                        }) })] })] }));
}
