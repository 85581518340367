import { loadStripe } from '@stripe/stripe-js';
export const PAYMENT_LINKS = {
    SINGLE_TOKEN: 'price_single',
    STARTER_SUBSCRIPTION: 'price_starter',
    PRO_SUBSCRIPTION: 'price_pro'
};
export const TOKEN_PACKAGES = [
    {
        id: 'single',
        tokens: 1,
        price: 9.99,
        description: 'Single Token',
        priceId: PAYMENT_LINKS.SINGLE_TOKEN,
        isSubscription: false
    },
    {
        id: 'starter',
        tokens: 3,
        price: 19.99,
        description: 'Starter Package',
        priceId: PAYMENT_LINKS.STARTER_SUBSCRIPTION,
        isSubscription: true
    },
    {
        id: 'pro',
        tokens: 10,
        price: 49.99,
        description: 'Pro Package',
        priceId: PAYMENT_LINKS.PRO_SUBSCRIPTION,
        isSubscription: true
    }
];
const stripePromise = loadStripe(import.meta.env.VITE_STRIPE_PUBLISHABLE_KEY);
export const createCheckoutSession = async (priceId, userId) => {
    try {
        const response = await fetch('/.netlify/functions/create-checkout-session', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                priceId,
                userId,
                mode: 'payment',
                successUrl: `${window.location.origin}/dashboard?session_id={CHECKOUT_SESSION_ID}`,
                cancelUrl: `${window.location.origin}/payment`,
            }),
        });
        const { id: sessionId } = await response.json();
        const stripe = await stripePromise;
        if (!stripe)
            throw new Error('Stripe failed to initialize');
        const { error } = await stripe.redirectToCheckout({ sessionId });
        if (error)
            throw error;
    }
    catch (error) {
        console.error('Payment error:', error);
        throw error;
    }
};
