import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useState } from 'react';
import { Music2, Play, ExternalLink } from 'lucide-react';
import Footer from '../components/Footer';
import ParallaxBackground from '../components/ParallaxBackground';
import ReactPlayer from 'react-player';
export default function Samples() {
    const [playingId, setPlayingId] = useState(null);
    const sampleSongs = [
        {
            id: '1',
            title: "TheMelodyMade.com Theme Song",
            description: "Experience our signature theme song showcasing our musical style and creativity.",
            videoUrl: "https://youtu.be/4wR7J0cq5B8",
            thumbnailUrl: `https://img.youtube.com/vi/4wR7J0cq5B8/maxresdefault.jpg`
        },
        {
            id: '2',
            title: "Nonna Maria's Tango",
            description: "A passionate Argentine tango celebrating the life of Nonna Maria, weaving together memories of her beloved Buenos Aires and the warmth of her family gatherings.",
            videoUrl: "https://youtu.be/xmWdSAUUogo",
            thumbnailUrl: "https://images.unsplash.com/photo-1516450360452-9312f5e86fc7?auto=format&fit=crop&w=1920&q=80"
        },
        {
            id: '3',
            title: "Custom Song Showcase",
            description: "Another beautiful example of our personalized musical creations.",
            videoUrl: "https://youtu.be/ehCSxIB1668",
            thumbnailUrl: `https://img.youtube.com/vi/ehCSxIB1668/maxresdefault.jpg`
        }
    ];
    const handleClick = (song) => {
        window.open(song.videoUrl, '_blank');
        setPlayingId(song.id);
    };
    return (_jsxs("div", { className: "min-h-screen bg-background text-foreground pt-24 relative", children: [_jsx(ParallaxBackground, {}), _jsxs("div", { className: "relative max-w-6xl mx-auto px-4 pb-12", children: [_jsxs("div", { className: "flex items-center gap-3 mb-8", children: [_jsx(Music2, { className: "w-8 h-8 text-purple-400" }), _jsx("h1", { className: "text-4xl font-bold text-purple-400", children: "Sample Songs" })] }), _jsx("div", { className: "grid md:grid-cols-2 lg:grid-cols-3 gap-8", children: sampleSongs.map((song) => (_jsxs("div", { className: "card group", children: [_jsxs("div", { className: "relative aspect-video", children: [_jsx("img", { src: song.thumbnailUrl, alt: song.title, className: "w-full h-full object-cover rounded-t-xl transition-transform duration-300 group-hover:scale-105" }), _jsx("div", { className: "absolute inset-0 bg-black/50 transition-opacity duration-300 group-hover:opacity-75 rounded-t-xl" }), _jsxs("button", { onClick: () => handleClick(song), className: "absolute inset-0 flex items-center justify-center gap-2", children: [_jsx(Play, { className: "w-16 h-16 text-white transition-transform duration-300 group-hover:scale-110" }), _jsx(ExternalLink, { className: "absolute top-4 right-4 w-6 h-6 text-white opacity-0 group-hover:opacity-100 transition-opacity duration-300" })] })] }), _jsxs("div", { className: "p-4", children: [_jsx("h3", { className: "text-xl font-bold text-purple-400 mb-2", children: song.title }), _jsx("p", { className: "text-muted", children: song.description })] })] }, song.id))) }), _jsx("div", { className: "hidden", children: sampleSongs.map((song) => (_jsx(ReactPlayer, { url: song.videoUrl, playing: playingId === song.id, onEnded: () => setPlayingId(null), width: "0", height: "0" }, song.id))) })] }), _jsx(Footer, {})] }));
}
