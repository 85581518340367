import { create } from 'zustand';
import { doc, getDoc, onSnapshot } from 'firebase/firestore';
import { auth, db } from '../lib/firebase';
import { signIn, signUp, sendPasswordResetEmail } from '../lib/auth/service';
import toast from 'react-hot-toast';
export const useAuthStore = create((set, get) => {
    let unsubscribeAuth = null;
    let unsubscribeProfile = null;
    // Set up auth state observer
    const setupAuthObserver = () => {
        unsubscribeAuth = auth.onAuthStateChanged(async (user) => {
            if (user) {
                try {
                    // Clean up previous profile subscription if exists
                    if (unsubscribeProfile) {
                        unsubscribeProfile();
                    }
                    // Set up real-time profile updates
                    const userRef = doc(db, 'users', user.uid);
                    unsubscribeProfile = onSnapshot(userRef, (doc) => {
                        if (doc.exists()) {
                            set({
                                user,
                                profile: doc.data(),
                                loading: false,
                                error: null
                            });
                        }
                        else {
                            set({
                                user,
                                profile: null,
                                loading: false,
                                error: 'User profile not found'
                            });
                        }
                    }, (error) => {
                        console.error('Profile sync error:', error);
                        set({ loading: false, error: error.message });
                        toast.error('Failed to load user profile');
                    });
                }
                catch (error) {
                    console.error('Auth observer error:', error);
                    set({ loading: false, error: error instanceof Error ? error.message : 'Authentication error' });
                    toast.error('Authentication error occurred');
                }
            }
            else {
                // Clean up profile subscription on sign out
                if (unsubscribeProfile) {
                    unsubscribeProfile();
                    unsubscribeProfile = null;
                }
                set({ user: null, profile: null, loading: false, error: null });
            }
        });
    };
    // Initialize auth observer
    setupAuthObserver();
    return {
        user: null,
        profile: null,
        loading: true,
        error: null,
        signUp: async (email, password, userData) => {
            try {
                set({ loading: true, error: null });
                await signUp(email, password, userData);
                toast.success('Account created successfully! You received 1 free token.');
            }
            catch (error) {
                const message = error instanceof Error ? error.message : 'Failed to create account';
                set({ error: message });
                toast.error(message);
                throw error;
            }
            finally {
                set({ loading: false });
            }
        },
        signIn: async (email, password) => {
            try {
                set({ loading: true, error: null });
                await signIn(email, password);
                toast.success('Signed in successfully!');
            }
            catch (error) {
                const message = error instanceof Error ? error.message : 'Failed to sign in';
                set({ error: message });
                toast.error(message);
                throw error;
            }
            finally {
                set({ loading: false });
            }
        },
        signOut: async () => {
            try {
                await auth.signOut();
                set({ user: null, profile: null, error: null });
                toast.success('Signed out successfully!');
            }
            catch (error) {
                const message = error instanceof Error ? error.message : 'Failed to sign out';
                set({ error: message });
                toast.error(message);
                throw error;
            }
        },
        resetPassword: async (email) => {
            try {
                await sendPasswordResetEmail(email);
                toast.success('Password reset email sent!');
            }
            catch (error) {
                const message = error instanceof Error ? error.message : 'Failed to send reset email';
                toast.error(message);
                throw error;
            }
        },
        refreshProfile: async () => {
            const { user } = get();
            if (!user)
                return;
            try {
                const userDoc = await getDoc(doc(db, 'users', user.uid));
                if (userDoc.exists()) {
                    set({ profile: userDoc.data() });
                }
            }
            catch (error) {
                console.error('Error refreshing profile:', error);
                toast.error('Error loading profile');
            }
        }
    };
});
