import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import VideoHeader from '../components/VideoHeader';
import KidsSection from '../components/KidsSection';
import WhyChooseUsSection from '../components/WhyChooseUsSection';
import PricingSection from '../components/PricingSection';
import ReviewsSection from '../components/ReviewsSection';
import EntertainmentSection from '../components/EntertainmentSection';
import Footer from '../components/Footer';
export default function Home() {
    return (_jsxs("div", { className: "min-h-screen text-gray-100 relative overflow-x-hidden", children: [_jsxs("div", { className: "relative", children: [_jsx(VideoHeader, {}), _jsx("div", { className: "max-w-6xl mx-auto px-4", children: _jsxs("main", { className: "space-y-32 sm:space-y-40", children: [_jsx(EntertainmentSection, {}), _jsx(KidsSection, {}), _jsx(PricingSection, {}), _jsx(ReviewsSection, {}), _jsx(WhyChooseUsSection, {})] }) })] }), _jsx(Footer, {})] }));
}
