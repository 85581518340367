import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React from 'react';
import { Plus, Minus } from 'lucide-react';
import Footer from '../components/Footer';
import ParallaxBackground from '../components/ParallaxBackground';
export default function FAQ() {
    const [openIndex, setOpenIndex] = React.useState(null);
    const faqs = [
        {
            question: "How long does it take to receive my custom song?",
            answer: "We guarantee delivery within 24 hours of your order confirmation. Our team of professional musicians works efficiently to create your perfect custom song while maintaining the highest quality standards."
        },
        {
            question: "What genres of music do you offer?",
            answer: "We offer a wide range of genres including Pop, Rock, Hip Hop, R&B, Country, Jazz, Classical, Electronic, Folk, Blues, and specialized styles like Disney and Kids Music. You can specify your preferred genre during the ordering process."
        },
        {
            question: "Can I request revisions to my song?",
            answer: "Yes! Each order includes two rounds of revisions to ensure your complete satisfaction. Additional revisions can be requested for a small fee."
        },
        {
            question: "What information do you need to create my song?",
            answer: "We need the names to be included, the occasion, preferred genre, and any specific details or situations you'd like incorporated into the lyrics. The more details you provide, the more personalized your song will be."
        },
        {
            question: "Do you offer refunds?",
            answer: "We offer a 100% satisfaction guarantee. If you're not completely satisfied with your song after the included revision rounds, we'll provide a full refund."
        },
        {
            question: "Can I book you for live events?",
            answer: "Yes! We offer special packages for live events. Please contact us directly at themelodymade@gmail.com or call (856) 874-6640 for custom event pricing and availability."
        },
        {
            question: "What format will I receive my song in?",
            answer: "Your song will be delivered as a high-quality MP3 file. Other formats are available upon request."
        },
        {
            question: "Can I use the song commercially?",
            answer: "Standard packages include personal use rights. For commercial licensing, please contact us to discuss your specific needs and obtain appropriate licensing."
        }
    ];
    return (_jsxs("div", { className: "min-h-screen bg-gray-900 text-gray-100 pt-24 relative", children: [_jsx(ParallaxBackground, {}), _jsxs("div", { className: "relative max-w-3xl mx-auto px-4", children: [_jsx("h1", { className: "text-4xl font-bold text-purple-400 text-center mb-12", children: "Frequently Asked Questions" }), _jsx("div", { className: "space-y-4", children: faqs.map((faq, index) => (_jsxs("div", { className: "bg-gray-800/90 backdrop-blur-sm rounded-xl overflow-hidden transition-all duration-300 card-hover", children: [_jsxs("button", { className: "w-full px-6 py-4 flex items-center justify-between text-left hover:bg-gray-700/50 transition-colors", onClick: () => setOpenIndex(openIndex === index ? null : index), children: [_jsx("span", { className: "font-medium text-purple-400", children: faq.question }), openIndex === index ? (_jsx(Minus, { className: "w-5 h-5 text-purple-400 flex-shrink-0" })) : (_jsx(Plus, { className: "w-5 h-5 text-purple-400 flex-shrink-0" }))] }), _jsx("div", { className: `px-6 transition-all duration-300 ${openIndex === index ? 'py-4' : 'h-0 overflow-hidden'}`, children: _jsx("p", { className: "text-gray-300", children: faq.answer }) })] }, index))) })] }), _jsx(Footer, {})] }));
}
