import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useEffect, useState } from 'react';
import { useThemeStore } from '../store/themeStore';
export default function ParallaxBackground() {
    const [scrollPosition, setScrollPosition] = useState(0);
    const [mousePosition, setMousePosition] = useState({ x: 0, y: 0 });
    const { isDark } = useThemeStore();
    useEffect(() => {
        const handleScroll = () => {
            const position = window.scrollY;
            setScrollPosition(position);
        };
        const handleMouseMove = (e) => {
            const x = (e.clientX / window.innerWidth - 0.5) * 20;
            const y = (e.clientY / window.innerHeight - 0.5) * 20;
            setMousePosition({ x, y });
        };
        window.addEventListener('scroll', handleScroll, { passive: true });
        window.addEventListener('mousemove', handleMouseMove, { passive: true });
        return () => {
            window.removeEventListener('scroll', handleScroll);
            window.removeEventListener('mousemove', handleMouseMove);
        };
    }, []);
    return (_jsxs("div", { className: "fixed inset-0 pointer-events-none overflow-hidden", children: [_jsx("div", { className: `absolute inset-0 transition-colors duration-300 ${isDark
                    ? 'bg-gradient-to-b from-gray-900 via-purple-900/5 to-gray-900'
                    : 'bg-gradient-to-b from-white via-purple-50/5 to-white'}`, style: {
                    transform: `translateY(${scrollPosition * 0.1}px) 
                     perspective(1000px) 
                     rotateX(${mousePosition.y * 0.02}deg) 
                     rotateY(${mousePosition.x * 0.02}deg)`,
                } }), _jsx("div", { className: `absolute inset-0 transition-opacity duration-300 ${isDark ? 'opacity-10' : 'opacity-5'}`, style: {
                    background: `radial-gradient(circle at ${50 + mousePosition.x * 0.5}% ${50 + mousePosition.y * 0.5}%, ${isDark ? 'rgba(147, 51, 234, 0.15)' : 'rgba(147, 51, 234, 0.1)'} 0%, transparent 70%)`,
                    transform: `translateY(${scrollPosition * 0.2}px) scale(1.5)`,
                } }), [...Array(20)].map((_, i) => (_jsx("div", { className: `absolute w-1 h-1 rounded-full transition-colors duration-300 ${isDark ? 'bg-purple-400/10' : 'bg-purple-600/5'}`, style: {
                    left: `${Math.random() * 100}%`,
                    top: `${Math.random() * 100}%`,
                    transform: `translateY(${scrollPosition * (0.1 + Math.random() * 0.2)}px)`,
                    animation: `float ${3 + Math.random() * 2}s ease-in-out infinite`,
                    animationDelay: `${-Math.random() * 2}s`,
                } }, i))), _jsx("div", { className: `absolute inset-0 transition-opacity duration-300 ${isDark ? 'opacity-30' : 'opacity-10'}`, style: {
                    background: `linear-gradient(${scrollPosition * 0.1}deg, ${isDark
                        ? 'rgba(147, 51, 234, 0.05), rgba(79, 70, 229, 0.05)'
                        : 'rgba(147, 51, 234, 0.03), rgba(79, 70, 229, 0.03)'})`,
                } })] }));
}
